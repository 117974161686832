import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  /**
   * These 3 farms (PID 0,1,2) should always be at the top of the file.
   */
  {
    pid: 0,
    lpSymbol: 'TUPB',
    lpAddresses: {
      97: '0x67a279317f357b994d808a986cc44716ace37627',
      56: '0x67a279317f357b994d808a986cc44716ace37627',
    },
    token: tokens.syrup,
    quoteToken: tokens.wbnb,
  },
 
 
  {
    pid: 1,
    lpSymbol: 'TUPB-BNB LP',
    lpAddresses: {
      97: '0xef7d43c5bfb4dee383be0aae5bce62cf3ced2625',
      56: '0xef7d43c5bfb4dee383be0aae5bce62cf3ced2625',
    },
    token: tokens.tupb,
    quoteToken: tokens.wbnb,
  },
  
  {
    pid: 2,
    lpSymbol: 'BUSD-BNB LP',
    lpAddresses: {
      97: '0x1b77de4e6415f2a0dbb6a76538b0857f2f0974ea',
      56: '0x1b77de4e6415f2a0dbb6a76538b0857f2f0974ea',
    },
    token: tokens.busd,
    quoteToken: tokens.wbnb,
  },
  
   
    

]

export default farms
