import React from 'react'
import styled from 'styled-components'
import { Spinner } from '@pancakeswap/uikit'
import Page from '../Layout/Page'
import logo from "../../assets/images/logo.png";

const Wrapper = styled(Page)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const PageLoader: React.FC = () => {
  return (
    <Wrapper>
      {/* <Spinner /> */}
      <div className="tupSpinner">
        <img src={logo} alt="tenup" />
      </div>
    </Wrapper>
  )
}

export default PageLoader
