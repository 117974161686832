import tokens from './tokens'
import { PoolConfig, PoolCategory } from './types'

const pools: PoolConfig[] = [

  {
    sousId: 0,
    stakingToken: tokens.tupb,
    earningToken: tokens.tupb,
    contractAddress: {
      97: '0xb6af9817a22065286f908ea7be9e57a6017d2e28',
      56: '0xb6af9817a22065286f908ea7be9e57a6017d2e28',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.21',
    sortOrder: 1,
    isFinished: false,
  },
  
  
  
]

export default pools
